import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../Styles/ValidTicket.module.css';

const ValidTicket = () => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
            <Link to="/ochemba/tickets/dashboard">
                <img src="/back-btn.webp" alt="back-button" className={styles.icon} />
                </Link>
                <div className={styles.verifyImage}>
                    <img src="/valid-status.webp" alt="valid-status" className={styles.valid} />
                    <p className={styles.instruction}>Ticket validation successful!</p>
                </div>
            </div>
            <div className={styles.formSection}>
                <div className={styles.formDetails}>
                    <div className={styles.field}>
                        <p className={styles.label}>Username</p>
                        <div className={styles.value}>
                            <svg width="345" height="75" viewBox="0 0 236 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_d_1613_757)">
                                    <path d="M231.596 0.742278C231.582 0.332934 231.237 0.0132996 230.828 0.0296516L7.51713 8.95189C7.11076 8.96813 6.79388 9.30965 6.80808 9.7161L7.09771 18.0102C7.11164 18.409 7.43965 18.7247 7.83869 18.7234L26.3737 18.6627L8.55396 19.4743C8.23731 19.4887 7.96512 19.7035 7.87742 20.0081L5.18464 29.3618C5.05032 29.8283 5.39472 30.2954 5.88013 30.3051L17.5224 30.5363L6.37079 31.0909C6.05804 31.1065 5.78905 31.3174 5.69942 31.6175L4.37043 36.0662C4.22793 36.5432 4.58823 37.0217 5.08602 37.0167L23.1487 36.8331L4.65834 37.6013C4.33005 37.6149 4.05019 37.8437 3.97151 38.1627L3.40031 40.4787C3.28299 40.9543 3.65257 41.4104 4.14222 41.3942L231.606 33.8787C232.143 33.861 232.482 33.2944 232.244 32.8127L227.362 22.9349C227.238 22.6838 226.983 22.5244 226.703 22.5233L211.576 22.4649L228.531 21.8728C229.039 21.8551 229.378 21.3424 229.195 20.8684L225.456 11.1705C225.343 10.8765 225.056 10.6868 224.741 10.6978L206.673 11.3287L228.693 9.62439C228.968 9.60312 229.209 9.43058 229.316 9.17687L231.253 4.62455C231.462 4.13399 231.098 3.5906 230.565 3.59631L221.725 3.6911L230.97 2.88322C231.362 2.84899 231.658 2.51413 231.644 2.12123L231.596 0.742278Z" fill="#f2d9b7" />
                                </g>
                                <text x="50%" y="50%" text-anchor="middle" fill="#514026" dy=".3em" className={styles.formName}>EMEKA ONYEBUCHI</text>
                                <defs>
                                    <filter id="filter0_d_1613_757" x="0.422393" y="0.0292969" width="234.855" height="47.2773" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feOffset dy="2.95602" />
                                        <feGaussianBlur stdDeviation="1.47801" />
                                        <feComposite in2="hardAlpha" operator="out" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1613_757" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1613_757" result="shape" />
                                    </filter>
                                </defs>
                            </svg>
                        </div>
                    </div>
                    <div className={styles.field}>
                        <p className={styles.label}>Email</p>
                        <div className={styles.value}>
                            <svg width="345" height="75" viewBox="0 0 236 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_d_1613_757)">
                                    <path d="M231.596 0.742278C231.582 0.332934 231.237 0.0132996 230.828 0.0296516L7.51713 8.95189C7.11076 8.96813 6.79388 9.30965 6.80808 9.7161L7.09771 18.0102C7.11164 18.409 7.43965 18.7247 7.83869 18.7234L26.3737 18.6627L8.55396 19.4743C8.23731 19.4887 7.96512 19.7035 7.87742 20.0081L5.18464 29.3618C5.05032 29.8283 5.39472 30.2954 5.88013 30.3051L17.5224 30.5363L6.37079 31.0909C6.05804 31.1065 5.78905 31.3174 5.69942 31.6175L4.37043 36.0662C4.22793 36.5432 4.58823 37.0217 5.08602 37.0167L23.1487 36.8331L4.65834 37.6013C4.33005 37.6149 4.05019 37.8437 3.97151 38.1627L3.40031 40.4787C3.28299 40.9543 3.65257 41.4104 4.14222 41.3942L231.606 33.8787C232.143 33.861 232.482 33.2944 232.244 32.8127L227.362 22.9349C227.238 22.6838 226.983 22.5244 226.703 22.5233L211.576 22.4649L228.531 21.8728C229.039 21.8551 229.378 21.3424 229.195 20.8684L225.456 11.1705C225.343 10.8765 225.056 10.6868 224.741 10.6978L206.673 11.3287L228.693 9.62439C228.968 9.60312 229.209 9.43058 229.316 9.17687L231.253 4.62455C231.462 4.13399 231.098 3.5906 230.565 3.59631L221.725 3.6911L230.97 2.88322C231.362 2.84899 231.658 2.51413 231.644 2.12123L231.596 0.742278Z" fill="#f2d9b7" />
                                </g>
                                <text x="50%" y="50%" text-anchor="middle" fill="#514026" dy=".3em" className={styles.formName}>chybyke@gmail.com</text>
                                <defs>
                                    <filter id="filter0_d_1613_757" x="0.422393" y="0.0292969" width="234.855" height="47.2773" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feOffset dy="2.95602" />
                                        <feGaussianBlur stdDeviation="1.47801" />
                                        <feComposite in2="hardAlpha" operator="out" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1613_757" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1613_757" result="shape" />
                                    </filter>
                                </defs>
                            </svg>
                        </div>
                    </div>
                    <div className={styles.field}>
                        <p className={styles.label}>Ticket</p>
                        <div className={styles.value}>
                            <svg width="345" height="75" viewBox="0 0 236 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_d_1613_757)">
                                    <path d="M231.596 0.742278C231.582 0.332934 231.237 0.0132996 230.828 0.0296516L7.51713 8.95189C7.11076 8.96813 6.79388 9.30965 6.80808 9.7161L7.09771 18.0102C7.11164 18.409 7.43965 18.7247 7.83869 18.7234L26.3737 18.6627L8.55396 19.4743C8.23731 19.4887 7.96512 19.7035 7.87742 20.0081L5.18464 29.3618C5.05032 29.8283 5.39472 30.2954 5.88013 30.3051L17.5224 30.5363L6.37079 31.0909C6.05804 31.1065 5.78905 31.3174 5.69942 31.6175L4.37043 36.0662C4.22793 36.5432 4.58823 37.0217 5.08602 37.0167L23.1487 36.8331L4.65834 37.6013C4.33005 37.6149 4.05019 37.8437 3.97151 38.1627L3.40031 40.4787C3.28299 40.9543 3.65257 41.4104 4.14222 41.3942L231.606 33.8787C232.143 33.861 232.482 33.2944 232.244 32.8127L227.362 22.9349C227.238 22.6838 226.983 22.5244 226.703 22.5233L211.576 22.4649L228.531 21.8728C229.039 21.8551 229.378 21.3424 229.195 20.8684L225.456 11.1705C225.343 10.8765 225.056 10.6868 224.741 10.6978L206.673 11.3287L228.693 9.62439C228.968 9.60312 229.209 9.43058 229.316 9.17687L231.253 4.62455C231.462 4.13399 231.098 3.5906 230.565 3.59631L221.725 3.6911L230.97 2.88322C231.362 2.84899 231.658 2.51413 231.644 2.12123L231.596 0.742278Z" fill="#f2d9b7" />
                                </g>
                                <text x="50%" y="50%" text-anchor="middle" fill="#514026" dy=".3em" className={styles.formName}>Singles Ticket</text>
                                <defs>
                                    <filter id="filter0_d_1613_757" x="0.422393" y="0.0292969" width="234.855" height="47.2773" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feOffset dy="2.95602" />
                                        <feGaussianBlur stdDeviation="1.47801" />
                                        <feComposite in2="hardAlpha" operator="out" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1613_757" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1613_757" result="shape" />
                                    </filter>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ValidTicket;
