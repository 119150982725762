import React from "react";
import AboutUs from "./AboutUs";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import HomePage from "./HomePage";
import servicesPage from "./ServicesPage";
import Products from "./Product";
import MeetTheTeam from "./MeetTheTeam";
import PageNotFOund from "./pageNotFound";
import Store from "./Components/Store/Store";
import PrivacyPolicy from "./PrivacyPolicy";
import OchembaEnuguTickets from "./pages/OchembaTickets/Components/OchembaTickets";
import OchembaCheckoutPage from "./pages/OchembaTickets/Pages/Checkout/OchembaCheckout";
import OchembaVerification from "./pages/OchembaTickets/Pages/VerificationPages/Login/Login";
import OchembaRegister from "./pages/OchembaTickets/Pages/VerificationPages/SignUp/SignUp";
import OchembaReceipt from "./pages/OchembaTickets/Pages/TicketingPage/ReceiptPage/Receipt";
import OchembaDashboard from "./pages/OchembaTickets/Pages/DashboardPage/Dashboard/Dashboard";
import OchembaScanTicket from "./pages/OchembaTickets/Pages/DashboardPage/ScanTicket/ScanTicket";
import OchembaValidTicket from "./pages/OchembaTickets/Pages/DashboardPage/ValidTicket/ValidTicket";
import OchembaInvalidTicket from "./pages/OchembaTickets/Pages/DashboardPage/InvalidTicket/InvalidTicket";
import OchembaTransactionHistory from "./pages/OchembaTickets/Pages/DashboardPage/TransactionHistory/TransactionHistory";
import OchembaTicketHistory from "./pages/OchembaTickets/Pages/DashboardPage/TicketHistory/TicketHistory";


// const DefaultRoutes = () => {
//   return (
//     <Layout>
//       <Switch>
//         <Route path="/" exact component={HomePage} />
//         <Route path="/ServicePage" component={ServicesPage} />
//         <Route path="/AboutUs" component={AboutUs} />
//         <Route path="/Products" component={Products} />
//         <Route path="/MeetTheTeam" component={MeetTheTeam} />
//         <Route path="*" component={ExternalRoutes} />
//       </Switch>
//     </Layout>
//   );
// };

// const ExternalRoutes = () => {
//   return (
//     <Switch>
//       <Route path="/Store" component={ComingSoon} />
//       <Route path="*" component={PageNotFOund} />
//     </Switch>
//   );
// };

class App extends React.Component {
  render() {
    return (
      <div id="app-container">
        <BrowserRouter>
          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route path="/services" component={servicesPage} />
            <Route path="/about-us" component={AboutUs} />
            <Route path="/products" component={Products} />
            <Route path="/meet-the-team" component={MeetTheTeam} />
            <Route path="/store" component={Store} />
            <Route path="/privacy-policy/tranzakt" component={PrivacyPolicy} />
            <Route path="/ochemba/tickets/checkout" component={OchembaCheckoutPage} />
            <Route path="/ochemba/tickets/verification" component={OchembaVerification} />
            <Route path="/ochemba/tickets/register" component={OchembaRegister} />
            <Route path="/ochemba/tickets/receipt" component={OchembaReceipt} />
            <Route path="/ochemba/tickets/dashboard" component={OchembaDashboard} />
            <Route path="/ochemba/tickets/scanticket" component={OchembaScanTicket} />
            <Route path="/ochemba/tickets/success" component={OchembaValidTicket} />
            <Route path="/ochemba/tickets/invalid" component={OchembaInvalidTicket} />
            <Route path="/ochemba/tickets/transactions" component={OchembaTransactionHistory} />
            <Route path="/ochemba/tickets/history" component={OchembaTicketHistory} />
            <Route path="/ochemba/tickets" component={OchembaEnuguTickets} />
         
            <Route component={PageNotFOund} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
