import React from 'react';
import InputField from '../InputField/InputField';
import styles from '../Styles/Dashboard.module.css';

const Dashboard = () => {
  return (
    <div className={styles.dashboardCover}>
      <div className={styles.dashboard}>
        <header className={styles.header}>
          <h1>Ticket Verification System by Scuudu Labs</h1>
        </header>
        <section className={styles.welcome}>
          <h2>Hi, Admin!</h2>
          <p>
            Welcome to your dashboard. Verify tickets seamlessly by scanning or
            inputting the reference number.
          </p>
          <div className={styles.icons}>
            <span className={styles.icon}>
              <img src='/mask.webp' alt='mask' />
            </span>
            <span className={styles.icon}>
              <img src='/mask.webp' alt='mask' />
            </span>
            <span className={styles.icon}>
              <img src='/mask.webp' alt='mask' />
            </span>
          </div>
        </section>
        <section className={styles.quickActions}>
          <h3>Quick Actions</h3>
          <div className={styles.actions}>
            <div>
              <div className={styles.action}>
                <img src='/scan-ticket.webp' alt='scan' />
              </div>
              <span className={styles.desc}>Scan ticket</span>
            </div>
            <div>
              <div className={styles.action}>
                <img src='/trans-history.webp' alt='history' />
              </div>
              <span className={styles.desc}>Ticket history</span>
            </div>
            <div>
              <div className={styles.action}>
                <img src='/logout-2.webp' alt='logout' />
              </div>
              <span className={styles.desc}>Logout</span>
            </div>
          </div>
        </section>
        <InputField />
      </div>
    </div>
  );
};

export default Dashboard;
