import React, { useState } from 'react';
import styles from '../Styles/InputField.module.css';

const InputField = () => {
  const [reference, setReference] = useState('');

  const handleScan = () => {
    alert(`Scanning ticket for reference: ${reference}`);
  };

  return (
    <div className={styles.inputField}>
      <label htmlFor="referenceInput" className={styles.label}>
        Input reference number manually
      </label>
      <input
        type="text"
        id="referenceInput"
        placeholder="BRN987654321"
        value={reference}
        onChange={(e) => setReference(e.target.value)}
        className={styles.input}
      />
      <div>
        <button className={styles.scanButton} onClick={handleScan}>
          Scan
        </button>
      </div>
    </div>
  );
};

export default InputField;
