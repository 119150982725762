import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import './SignUp.css';

const RegisterPage = () => {
  return (
    <div className="login-section">
        <div className="login-container">
            <div className="logo-section">
                <img src="/ochemba-shield.webp" alt="Ochemba Enugu" className="logo" />
            </div>
            <h2 className="login-header">Hello, register now</h2>
            <div className="form-section">
                <div className="tabs">
                <span>
                <Link to="/ochemba/tickets/verification" className="login-text">Login</Link>
                </span>
                <span className="active">
                <Link to="/ochemba/tickets/register" className="register-text">Sign up</Link>
                </span>
                </div>
                <form>
                    <div className="input-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" placeholder="example@gmail.com" />
                    </div>
                    <div className="input-group">
                        <label htmlFor="password">Create password</label>
                        <input type="password" id="password" placeholder="********" />
                    </div>
                    <button type="submit" className="register-btn">Sign up</button>
                </form>
            </div>
      </div>
    </div>
  );
};

export default RegisterPage;