import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../Styles/ScanTicket.module.css';

const ScanTicket = () => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
            <Link to="/ochemba/tickets/dashboard">
                <img src="/back-btn.webp" alt="back-button" className={styles.icon} />
            </Link>
                <div className={styles.scanImage}>
                    <p className={styles.instruction}>Hold camera on the QR Code <br /> to Scan ticket</p>
                </div>
            </div>
                <div className={styles.qrSection}>
                    <img src="/qr-code.webp" alt="QR Code" className={styles.qrCode} />
                    <p className={styles.description}>Scan ticket QR code by holding your phone camera above the QR code.</p>
                    <div className={styles.footer}>
                    <button className={styles.openCamera}>Open camera</button>
                </div>
                </div>

            </div>
    );
};

export default ScanTicket;
