import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from '../Styles/TransactionHistory.module.css';


const TransactionHistory = () => {
    const [activeTab, setActiveTab] = useState("Today");

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <Link to="/ochemba/tickets/dashboard">
                    <img src="/back-btn.webp" alt="back-button" className={styles.icon} />
                </Link>
                <h2>Payments History</h2>
                <div className={styles.tabs}>
                    <button
                        className={`${styles.tab} ${activeTab === "Today" ? styles.active : ""}`}
                        onClick={() => handleTabClick("Today")}>
                        Today
                    </button>
                    <button
                        className={`${styles.tab} ${activeTab === "Yesterday" ? styles.active : ""}`}
                        onClick={() => handleTabClick("Yesterday")}
                    >
                        Yesterday
                    </button>
                    <button
                        className={`${styles.tab} ${activeTab === "Older" ? styles.active : ""}`}
                        onClick={() => handleTabClick("Older")}>
                        Older
                    </button>
                </div>
            </div>
            <div className={styles.paymentsList}>
                <div className={styles.paymentItem}>
                    <div className={styles.paymentIcon}>
                        <img src="/up-arrow.webp" alt="Arrow-Up" className={styles.arrow} />
                    </div>
                    <div className={styles.details}>
                        <p className={styles.name}>Mamuzou Akpo</p>
                        <p className={styles.ticketType}>Singles ticket</p>
                    </div>
                    <div className={styles.amount}>+ NGN 3,000.00</div>
                    <div className={styles.timestamp}>14:37 | 1 DEC, 2023</div>
                </div>
                <div className={styles.paymentItem}>
                    <div className={styles.paymentIcon}>
                    <img src="/up-arrow.webp" alt="Arrow-Up" className={styles.arrow} />
                    </div>
                    <div className={styles.details}>
                        <p className={styles.name}>Tejiri Precious</p>
                        <p className={styles.ticketType}>Couples ticket</p>
                    </div>
                    <div className={styles.amount}>+ NGN 5,000.00</div>
                    <div className={styles.timestamp}>16:37 | 1 DEC, 2023</div>
                </div>
                <div className={styles.paymentItem}>
                    <div className={styles.paymentIcon}>
                    <img src="/up-arrow.webp" alt="Arrow-Up" className={styles.arrow} />
                    </div>
                    <div className={styles.details}>
                        <p className={styles.name}>Luther Ope</p>
                        <p className={styles.ticketType}>Singles ticket</p>
                    </div>
                    <div className={styles.amount}>+ NGN 3,000.00</div>
                    <div className={styles.timestamp}>14:37 | 1 DEC, 2023</div>
                </div>
                <div className={styles.paymentItem}>
                    <div className={styles.paymentIcon}>
                    <img src="/up-arrow.webp" alt="Arrow-Up" className={styles.arrow} />
                    </div>
                    <div className={styles.details}>
                        <p className={styles.name}>Luther Ope</p>
                        <p className={styles.ticketType}>Singles ticket</p>
                    </div>
                    <div className={styles.amount}>+ NGN 3,000.00</div>
                    <div className={styles.timestamp}>14:37 | 1 DEC, 2023</div>
                </div>
                <div className={styles.paymentItem}>
                    <div className={styles.paymentIcon}>
                    <img src="/up-arrow.webp" alt="Arrow-Up" className={styles.arrow} />
                    </div>
                    <div className={styles.details}>
                        <p className={styles.name}>Luther Ope</p>
                        <p className={styles.ticketType}>Singles ticket</p>
                    </div>
                    <div className={styles.amount}>+ NGN 3,000.00</div>
                    <div className={styles.timestamp}>14:37 | 1 DEC, 2023</div>
                </div>
                <div className={styles.paymentItem}>
                    <div className={styles.paymentIcon}>
                    <img src="/up-arrow.webp" alt="Arrow-Up" className={styles.arrow} />
                    </div>
                    <div className={styles.details}>
                        <p className={styles.name}>Luther Ope</p>
                        <p className={styles.ticketType}>Singles ticket</p>
                    </div>
                    <div className={styles.amount}>+ NGN 3,000.00</div>
                    <div className={styles.timestamp}>14:37 | 1 DEC, 2023</div>
                </div>
                <div className={styles.paymentItem}>
                    <div className={styles.paymentIcon}>
                    <img src="/up-arrow.webp" alt="Arrow-Up" className={styles.arrow} />
                    </div>
                    <div className={styles.details}>
                        <p className={styles.name}>Luther Ope</p>
                        <p className={styles.ticketType}>Singles ticket</p>
                    </div>
                    <div className={styles.amount}>+ NGN 3,000.00</div>
                    <div className={styles.timestamp}>14:37 | 1 DEC, 2023</div>
                </div>
                <div className={styles.paymentItem}>
                    <div className={styles.paymentIcon}>
                    <img src="/up-arrow.webp" alt="Arrow-Up" className={styles.arrow} />
                    </div>
                    <div className={styles.details}>
                        <p className={styles.name}>Luther Ope</p>
                        <p className={styles.ticketType}>Singles ticket</p>
                    </div>
                    <div className={styles.amount}>+ NGN 3,000.00</div>
                    <div className={styles.timestamp}>14:37 | 1 DEC, 2023</div>
                </div>
            </div>

        </div>
    );
};

export default TransactionHistory;
